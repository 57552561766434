import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseUrl from '../../../helpers/baseUrl';
import demoImage from '../../../assets/avater.jpg'

const BestSellingReport = () => {
    // State variables for filters
    const [singleDate, setSingleDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [serialId, setSerialId] = useState('');
    const [bestSellingProducts, setBestSellingProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Fetch the best-selling products based on filters
    const fetchBestSellingReport = async () => {
        setLoading(true);
        setError('');
        try {
            // Build query params
            const params = {};
            if (singleDate) params.singleDate = singleDate;
            if (startDate) params.startDate = startDate;
            if (endDate) params.endDate = endDate;
            if (serialId) params.serialId = serialId;

            const response = await axios.get(`${baseUrl}/api/orders/report-best-selling`, { params });

            setBestSellingProducts(response.data);
            console.log(response.data);

        } catch (err) {
            setError('Error fetching data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBestSellingReport();
    }, [singleDate, startDate, endDate, serialId]);

    // Clear all date filters
    const clearFilters = () => {
        setSingleDate('');
        setStartDate('');
        setEndDate('');
        setSerialId('');
    };

    return (
        <div className="container mx-auto py-10 px-4 mb-10">
            <h1 className="text-2xl font-bold mb-4">Best Selling Products Report</h1>

            {/* Filters */}
            <div className="mb-4">
                <label className="mr-2">Single Date:</label>
                <input
                    type="date"
                    value={singleDate}
                    onChange={(e) => setSingleDate(e.target.value)}
                    className="border p-2"
                />
            </div>
            <div className="mb-4">
                <label className="mr-2">Start Date:</label>
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="border p-2"
                />
                <label className="mr-2 ml-4">End Date:</label>
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="border p-2"
                />
            </div>
            <div className="mb-4">
                <label className="mr-2">Serial ID:</label>
                <select
                    value={serialId}
                    onChange={(e) => setSerialId(e.target.value)}
                    className="border p-2"
                >
                    <option value="">ALL</option>
                    <option value="showroom">Showroom</option>
                    <option value="online">Online (E-commerce, Store, Facebook, WhatsApp)</option>
                </select>
            </div>

            {/* Button to refresh the report */}
            {/* <button
                onClick={fetchBestSellingReport}
                className="bg-blue-500 text-white p-2 rounded mr-4"
            >
                Refresh Report
            </button> */}

            {/* Button to clear filters */}
            <button
                onClick={clearFilters}
                className="bg-red-500 text-white p-2 rounded"
            >
                Clear Filters
            </button>

            {/* Loading Indicator */}
            {loading && <p>Loading...</p>}

            {/* Error Message */}
            {error && <p className="text-red-500">{error}</p>}

            {/* Table to display best-selling products */}
            {!loading && !error && bestSellingProducts.length > 0 && (
                <table className="table-auto w-full mt-4">
                    <thead>
                        <tr>
                            <th className="border px-4 py-2">Serial</th>
                            <th className="border px-4 py-2">Images</th>
                            <th className="border px-4 py-2">Product Name</th>
                            <th className="border px-4 py-2">SKU</th>
                            <th className="border px-4 py-2">Category</th>
                            <th className="border px-4 py-2">Subcategory</th>
                            <th className="border px-4 py-2">Quantity Sold</th>
                            <th className="border px-4 py-2">Revenue</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bestSellingProducts.map((product, index) => (
                            <tr
                                key={product.productId}
                                className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} // Apply different background color for odd and even rows
                            >
                                <td className="border px-4 py-2">{index+1}</td>
                                <td className="border px-4 py-2">
                                    {product.images ? (
                                        <img src={product.images[0] ? `${baseUrl}/${product.images[0]}`:demoImage} alt={product.productName} className="w-16 h-16 object-cover" />
                                    ) : (
                                        'No Image'
                                    )}
                                </td>
                                <td className="border px-4 py-2">{product.productName}</td>
                                <td className="border px-4 py-2">{product.SKU}</td>
                                <td className="border px-4 py-2">{product.categoryName || 'N/A'}</td>
                                <td className="border px-4 py-2">{product.subcategoryName || 'N/A'}</td>
                                <td className="border px-4 py-2">{product.totalQuantity}</td>
                                <td className="border px-4 py-2">{product.totalRevenue} ৳</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Message if no products are found */}
            {!loading && !error && bestSellingProducts.length === 0 && (
                <p>No products found for the given filters.</p>
            )}
        </div>
    );
};

export default BestSellingReport;
